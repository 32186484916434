import React, { useState, useEffect } from 'react';
import { Button, Divider } from '@mantine/core'
import { valid, formataCPF, formatarTelefone, formatarCep, formataData, states, setCity } from '../../../../utils';
import moment from 'moment'
import Spinner from '../../../../assets/svgs/spinner'

import DetailsAddress from './components/detailsAddress';

import { HelpersAdress } from '../../../../helpers';
import DetailsData from './components/detailsData';
const helpersAdress = new HelpersAdress()

const DadosPessoais = ({ user, updateUser, error, loading }) => {
   const [name, setName] = useState(user.name)
   const [cpf, setCpf] = useState(user.cpf)
   const [rg, setRg] = useState(valid(user.rg) ? user.rg : '')
   const [birthdate, setBirthdate] = useState(valid(user.birthdate) ? moment(user.birthdate).format('DD/MM/YYYY') : '')
   const [email, setEmail] = useState(user.email)
   const [telefone, setTelefone] = useState(valid(user.phone) ? user.phone : '')
   const [cep, setCep] = useState(valid(user.address) ? user.address.cep : '')
   const [rua, setRua] = useState(valid(user.address) ? user.address.street : '')
   const [state, setState] = useState(valid(user.address) ? user.address.state_id : 1)
   const [citys, setCitys] = useState([]);
   const [cityId, setCityId] = useState(valid(user.address) ? user.address.city_id : '');
   const [cityName, setCityName] = useState('');
   const [district, setDistrict] = useState(valid(user.address) ? user.address.district : '');
   const [complement, setComplement] = useState(valid(user.address) ? user.address.complement : '');
   const [number, setNumber] = useState(valid(user.address) ? user.address.number : '');
   const [editAddress, setEditAdrress] = useState(false)
   const [editData, setEditData] = useState(false)
   
   useEffect(() => {
      const findCity = async(sta) => {
         await helpersAdress.ListCities(sta).then(resp => {
            setCitys(resp.data.data)
            const nome = setCity(resp.data.data, user.address.city_id)
            if(user.address !== null){
               setCityId(user.address.city_id)
               setCityName(nome)
            } 
         })
      } 
      setEditAdrress(false)
      setEditData(false)

      if(state > 0)  findCity(state)
   }, [state, user.address])

   const changeUser = (e) => {
      e.preventDefault();
      const usuario = {
         id: user.id,
         profile_id: 3,
         name: name,
         email: email,
         cpf: cpf.replace('.','').replace('.','').replace('-', ''),
         phone: telefone.replace('(','').replace(')','').replace('-', ''),
         rg: rg.replace('(','').replace(')','').replace('-', ''),
         birthdate: birthdate,
         city_id: cityId,
         state_id: state,
         cep: cep.replace('-', ''),
         street: rua,
         number: number,
         district: district,
         complement: complement
      }
      updateUser(usuario)
   } 
   

   return (
      <form className="space-y-6" onSubmit={changeUser}>
         <div className='hidden sm:block'>
            <p className='text-2xl font-bold text-gray-900 mx-10 mt-10'><i class="bi bi-person-circle"></i> Dados Pessoais:</p>
            <Divider className='my-5 mx-10'/>
            <div className='shadow py-5 mx-10 rounded-md bg-white'>
               {
                  editData ? <>
                        <div className='grid grid-cols-3'>
                        <div className='ml-5 mr-2'>
                           <label  className="block mb-2 text-sm font-semibold text-gray-900">
                              Nome: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="nome"
                              id="nome"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu nome" 
                              required
                              disabled
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                           />    
                        </div>

                        <div className='ml-2 mr-2 '>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              CPF: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="CPF"
                              id="CPF"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu CPF" 
                              maxLength={14}
                              required
                              disabled
                              value={formataCPF(cpf)}
                              onChange={(e) => setCpf(e.target.value)}
                           />    
                        </div>

                        <div className='ml-2 mr-5'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              RG: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="rg"
                              id="rg"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu RG" 
                              required
                              maxLength={12}
                              value={rg}
                              onChange={(e) => setRg(e.target.value)}
                           />    
                        </div>
                     </div>

                     <div className='grid grid-cols-4 mt-3'>
                        <div className='ml-5 mr-2 col-span-2'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Email:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="email"
                              name="email"
                              id="email"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu email" 
                              required
                              disabled={true}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                           />    
                        </div>
                        <div className='ml-2 mr-2'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Telefone:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="telefone"
                              id="telefone"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu telefone" 
                              required
                              maxLength={14}
                              minLength={14}
                              value={formatarTelefone(telefone)}
                              onChange={(e) => setTelefone(e.target.value)}
                           />    
                        </div>
                        <div className='ml-2 mr-5'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Data de nascimento:
                           </label>
                           <input
                              type="text"
                              name="dataNascimento"
                              id="dataNascimento"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite sua data de nascimento" 
                              maxLength={10}
                              value={formataData(birthdate)}
                              onChange={(e) => setBirthdate(e.target.value)}
                           />    
                        </div>
                     </div>
                     <div className='flex justify-end mt-10 mx-10 gap-3'>
                        <Button className="cursor-pointer" color='red' onClick={() => setEditData(false)}>
                           Cancelar
                        </Button>
                        <Button className="cursor-pointer bg-secondary" type="submit">
                           { loading ? <> <Spinner />Salvando</> : 'Salvar' }
                        </Button>
                     </div>
                  </>
                  :
                  <>
                     <div className="flex justify-between mx-5 mb-5">
                        <i className="bi bi-person-circle"></i>
                        <button 
                           className="font-medium border-none bg-transparent text-blue-500 hover:underline text-sm"
                           onClick={() =>  setEditData(true)}
                        >
                           Editar
                        </button>
                     </div>
                     <DetailsData 
                        name={name}
                        cpf={cpf}
                        rg={rg}
                        email={email}
                        telefone={telefone}
                        birthdate={birthdate}
                     />
                  </>
               }
               
            </div>

            <p className='mb-4 text-2xl font-bold text-gray-900 mx-10 mt-10'><i className="bi bi-geo-alt-fill"></i> Endereço:</p>
            <Divider className='my-5 mx-10'/>
            
            <div className='shadow py-5 mx-10 rounded-md bg-white'>
               {
                  editAddress ?
                  <>
                     <div className='grid grid-cols-4'>
                        <div className='ml-5 mr-2 col-span-1'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              CEP:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="cep"
                              id="cep"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu CEP" 
                              maxLength={9}
                              required
                              value={formatarCep(cep)}
                              onChange={(e) => setCep(e.target.value)}
                           />    
                        </div>
                        
                        <div className='ml-2 mr-2 col-span-2'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Endereço:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="endereco"
                              id="endereco"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite sua rua" 
                              required
                              value={rua}
                              onChange={(e) => setRua(e.target.value)}
                           />    
                        </div>

                        <div className='ml-2 mr-10'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Estado:<span className='text-[red]'>*</span>
                           </label>
                           <select
                              type="text"
                              name="estado"
                              id="estado"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-3 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Selecione seu estado" 
                              required
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                           >  
                              <option value="">Selecione o estado</option>
                              {
                                 states.map((item, index) => 
                                    <option value={item.id} key={index.toString()}>{item.name}</option>
                                 )
                              }
                           </select>
                        </div>
                     </div>

                     <div className='grid grid-cols-9 mt-3'>
                        <div className='ml-5 mr-2 col-span-2'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Cidade:<span className='text-[red]'>*</span>
                           </label>
                           <select
                              type="text"
                              name="cidade"
                              id="cidade"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-3 border-solid border-2 focus:border-default-orange outline-none"
                              required
                              value={cityId}
                              onChange={e => setCityId(e.target.value)}
                           >  
                              <option value="" >Selecione a cidade</option>
                              {
                                 citys.map((item, index) => 
                                    <option value={item.id} key={index.toString()}>{item.name}</option>
                                 )
                              }
                           </select>    
                        </div>
                        <div className='ml-2 mr-2 col-span-3'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Bairro:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="bairro"
                              id="bairro"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu bairro" 
                              required
                              value={district}
                              onChange={e => setDistrict(e.target.value)}
                           />    
                        </div>
                        <div className='ml-2 mr-2 col-span-3'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Complemento:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="bairro"
                              id="bairro"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu bairro" 
                              required
                              value={complement}
                              onChange={e => setComplement(e.target.value)}
                           />    
                        </div>
                        <div className='ml-2 mr-10'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Número:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="numero"
                              id="numero"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite o número da sua casa" 
                              required
                              value={number}
                              onChange={e => setNumber(e.target.value)}
                           />    
                        </div>
                     </div>
                     <div className='flex justify-end mt-10 mx-10 gap-3'>
                        <Button className="cursor-pointer" color='red' onClick={() => setEditAdrress(false)}>
                           Cancelar
                        </Button>
                        <Button className="cursor-pointer bg-secondary" type="submit">
                           { loading ? <> <Spinner />Salvando</> : 'Salvar' }
                        </Button>
                     </div>   
                  </>
                  :
                  <>
                     <div className="flex justify-between mx-5 mb-5">
                        <i className="bi bi-geo-alt-fill"></i>
                        <button 
                           className="font-medium border-none bg-transparent text-blue-500 hover:underline text-sm"
                           onClick={() => setEditAdrress(true)}
                        >
                           Editar
                        </button>
                     </div>
                     <DetailsAddress
                        cep={cep}
                        rua={rua}
                        state={state}
                        city={cityName}
                        district={district}
                        complement={complement}
                        number={number}
                     /> 
                  </>
               } 
            </div>
         </div>

         <div className="block sm:hidden space-y-3 mx-2">
            <p className='text-2xl font-bold text-gray-900 mt-10'><i class="bi bi-person-circle"></i> Dados Pessoais:</p>
            <Divider />
            <div>
               <input
                  type="text"
                  name="nome"
                  id="nome"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                  focus:border-blue-500 block w-full p-2.5"
                  placeholder="Digite seu nome" 
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="CPF"
                  id="CPF"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu CPF" 
                  maxLength={14}
                  required
                  disabled
                  value={formataCPF(cpf)}
                  onChange={(e) => setCpf(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="rg"
                  id="rg"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu RG" 
                  required
                  maxLength={12}
                  value={rg}
                  onChange={(e) => setRg(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu email" 
                  required
                  disabled={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="telefone"
                  id="telefone"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu telefone" 
                  required
                  maxLength={16}
                  minLength={16}
                  value={formatarTelefone(telefone)}
                  onChange={(e) => setTelefone(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="dataNascimento"
                  id="dataNascimento"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite sua data de nascimento" 
                  maxLength={10}
                  minLength={10}
                  required
                  value={formataData(birthdate)}
                  onChange={(e) => setBirthdate(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="cep"
                  id="cep"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu CEP" 
                  maxLength={9}
                  minLength={9}
                  required
                  value={formatarCep(cep)}
                  onChange={(e) => setCep(e.target.value)}
               />    
            </div>

            <p className='mb-4 text-2xl font-bold text-gray-900 pt-10'><i className="bi bi-geo-alt-fill"></i> Endereço:</p>
            <Divider className='my-5'/>

            <div>
               <input
                  type="text"
                  name="endereco"
                  id="endereco"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite sua rua" 
                  required
                  value={rua}
                  onChange={(e) => setRua(e.target.value)}
               />    
            </div>
            <div>
               <select
                  type="text"
                  name="estado"
                  id="estado"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione seu estado" 
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
               >  
                  <option value="">Selecione o estado</option>
                  {
                     states.map((item, index) => 
                        <option value={item.id} key={index.toString()}>{item.name}</option>
                     )
                  }
               </select>
            </div>
            <div>
               <select
                  type="text"
                  name="cidade"
                  id="cidade"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione sua cidade" 
                  required
                  value={cityId}
                  onChange={e => setCityId(e.target.value)}
               >  
                  <option value="" >Selecione a cidade</option>
                  {
                     citys.map((item, index) => 
                        <option value={item.id} key={index.toString()}>{item.name}</option>
                     )
                  }
               </select>    
            </div>
            <div>
               <input
                  type="text"
                  name="bairro"
                  id="bairro"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu bairro" 
                  required
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="bairro"
                  id="bairro"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu bairro" 
                  required
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
               />    
            </div>
            <div>
               <input
                  type="text"
                  name="numero"
                  id="numero"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite o número da sua casa" 
                  required
                  value={number}
                  onChange={e => setNumber(e.target.value)}
               />    
            </div>
            <div className='flex pb-10 justify-end'>
               <Button className="cursor-pointer bg-secondary self-end" type="submit">
                  { loading ? <> <Spinner />Salvando</> : 'Salvar' }
               </Button>
            </div>
         </div>

         {
            error ? <div className='bg-red-300 rounded-lg p-2 mx-10'>
               <p className="text-red-600">
                  {error.message  ===
                     'The birthdate does not match the format d/m/Y.' ?
                     'A data de aniversário é inválida' : null
                  }
                  {error.message  ===
                     'O campo cpf não é um CPF válido.' ?
                     'O CPF é inválido' : null
                  }
                  {error.message  ===
                     'O campo cpf não é um CPF válido. (and 1 more error)' ?
                     'O CPF é inválido' : null
                  }
               </p>
            </div> : null
         }
      </form>
   );
};

export default DadosPessoais;