import React from 'react'
import { useSelector } from 'react-redux'
import { Divider } from '@mantine/core'

import { formatarTelefone, formatarCep } from '../utils';

const Adrress = ({ user, cep, setCep, rua, setRua, state, setState, citys, city, setCity, district, setDistrict, complement, setComplement, number, setNumber, telefone, setTelefone }) => {

   const states = useSelector(state => state.Auth.states)

   return (
      <div className="mr-4 space-y-4">
         <p className='mb-4 text-base font-semibold text-gray-900'>Endereço de entrega:</p>
         <div className='grid grid-cols-4 gap-4'>
            <div className=''>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  CEP: <span className='text-[red]'>*</span>
               </label>
               <input
                  type="text"
                  name="cep"
                  id="cep"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu CEP"
                  maxLength={9}
                  required
                  value={formatarCep(cep)}
                  onChange={(e) => setCep(e.target.value)}
               />
            </div>

            <div className='col-span-2'>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Endereço: <span className='text-[red]'>*</span>
               </label>
               <input
                  type="text"
                  name="endereco"
                  id="endereco"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite sua rua"
                  required
                  value={rua}
                  onChange={(e) => setRua(e.target.value)}
               />
            </div>

            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Estado:<span className='text-[red]'>*</span>
               </label>
               <select
                  type="text"
                  name="estado"
                  id="estado"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-3 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione seu estado"
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
               >
                  <option value="">Selecione o estado</option>
                  {
                     states.map((item, index) =>
                        <option value={item.id} key={index.toString()}>{item.name}</option>
                     )
                  }
               </select>
            </div>
         </div>

         <div className='grid grid-cols-3 gap-4'>
            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Cidade:<span className='text-[red]'>*</span>
               </label>
               <select
                  type="text"
                  name="cidade"
                  id="cidade"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-3 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione sua cidade"
                  required
                  value={city}
                  onChange={e => setCity(e.target.value)}
               >
                  <option value="">Selecione a cidade</option>
                  {
                     citys.map((item, index) =>
                        <option value={item.id} key={index.toString()}>{item.name}</option>
                     )
                  }
               </select>
            </div>
            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Bairro:<span className='text-[red]'>*</span>
               </label>
               <input
                  type="text"
                  name="bairro"
                  id="bairro"
                  className="bg-gray-50 border border-gray-300 
                        text-gray-900 text-sm rounded-lg  block w-full 
                        p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu bairro"
                  required
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
               />
            </div>
            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Complemento:
               </label>
               <input
                  type="text"
                  name="numero"
                  id="numero"
                  className="bg-gray-50 border border-gray-300 
                        text-gray-900 text-sm rounded-lg  block w-full 
                        p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Complemento"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
               />
            </div>
         </div>

         <div className='grid grid-cols-4 gap-4 pb-4'>
            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Número:<span className='text-[red]'>*</span>
               </label>
               <input
                  type="text"
                  name="numero"
                  id="numero"
                  className="bg-gray-50 border border-gray-300 
                        text-gray-900 text-sm rounded-lg  block w-full 
                        p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Número da sua casa/apto"
                  required
                  value={number}
                  onChange={e => setNumber(e.target.value)}
               />
            </div>
            <div className=''>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Telefone:<span className='text-[red]'>*</span>
               </label>
               <input
                  type="text"
                  name="telefone"
                  id="telefone"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu telefone"
                  required
                  maxLength={14}
                  value={formatarTelefone(telefone)}
                  onChange={(e) => setTelefone(e.target.value)}
               />
            </div>
         </div> 
      </div>
   )
}


export default Adrress