import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import Header from '../../components/Header'
import Address from '../../components/Address'
import Spinner from '../../assets/svgs/spinner'

import { formatarCep, states, formatarTelefone } from '../../utils'

import { loginUserSuccess } from '../../redux/actions'

import { HelpersAdress, HelpersAuth } from '../../helpers';
const helpersAdress = new HelpersAdress()
const helpersAuth = new HelpersAuth()

const Adress = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate();
   const user = useSelector(state => state.Auth.user)
   const [cep, setCep] = useState('');
   const [rua, setRua] = useState('');
   const [state, setState] = useState('');
   const [city, setCity] = useState('');
   const [citys, setCitys] = useState([]);
   const [district, setDistrict] = useState('');
   const [complement, setComplement] = useState('');
   const [number, setNumber] = useState('')
   const [telefone, setTelefone] = useState('')
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      const findCity = async (sta) => {
         await helpersAdress.ListCities(sta).then(resp => {
            setCitys(resp.data.data)
            if (user.address !== null) {
               setCity(user.address.city_id)
            }
         })
      }

      if (state > 0) findCity(state)
   }, [state, user.address])

   const updateUse = async (e) => {
      e.preventDefault();

      if (
         cep.trim() !== ''
         && rua.trim() !== ''
         && state !== ''
         && city !== ''
         && district.trim() !== ''
         && number !== ''
         && telefone.trim() !== ''
      ) {
         setLoading(true)

         const usuario = {
            id: user.id,
            profile_id: 3,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            phone: telefone.replace('(', '').replace(')', '').replace('-', '').trim(),
            rg: '',
            birthdate: '',
            city_id: city,
            state_id: state,
            cep: cep.replace('-', '').trim(),
            street: rua.trim(),
            number: number,
            district: district.trim(),
            complement: complement.trim()
         }

         await helpersAuth.UpdateUser(usuario)
            .then(async () => {
               const validToken = localStorage.getItem("token_cliente")
               const user = await helpersAuth.GetUser(validToken);
               dispatch(loginUserSuccess(user.data.data))
               navigate('/checkout')
               setLoading(false)
            })
      } else {
         toast("Preencha todos os dados", {
            type: "error"
         })
         setLoading(false)
      }
   }


   return (
      <>
         <Header title={'Endereço'} />
         <form className='bg-white mx-auto py-20 rounded-lg max-w-6xl hidden sm:block' onSubmit={updateUse}>
            <Address
               user={user}
               cep={cep} setCep={(e) => setCep(e)}
               rua={rua} setRua={(e) => setRua(e)}
               state={state} setState={(e) => setState(e)}
               citys={citys}
               city={city} setCity={(e) => setCity(e)}
               district={district} setDistrict={(e) => setDistrict(e)}
               complement={complement}
               setComplement={(e) => setComplement(e)}
               number={number} setNumber={(e) => setNumber(e)}
               telefone={telefone} setTelefone={(e) => setTelefone(e)}
            />

            <div className='flex justify-end mt-10 mx-4'>
               <button className="cursor-pointer  bg-default-orange hover:bg-default-blue p-3 rounded-xl text-white font-semibold text-2xl" type="submit" disabled={loading}>
                  {
                     loading ? <div className='flex items-center justify-center'>
                        <Spinner /> Salvando
                     </div> : "Salvar e continuar"
                  }
               </button>
            </div>

         </form>

         <div className='rounded-lg bg-white w-max-4xl p-5 block sm:hidden'>
            <form className="space-y-6" onSubmit={updateUse}>
               <div className="f">
                  <div className="space-y-2">
                     <p className='mb-4 text-base font-semibold text-gray-900'>Endereço de entrega:</p>
                     <div>
                        <input
                           type="text"
                           name="cep"
                           id="cep"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu CEP"
                           maxLength={9}
                           required
                           value={formatarCep(cep)}
                           onChange={(e) => setCep(e.target.value)}
                        />
                     </div>

                     <div>
                        <input
                           type="text"
                           name="endereco"
                           id="endereco"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite sua rua"
                           required
                           value={rua}
                           onChange={(e) => setRua(e.target.value)}
                        />
                     </div>

                     <div>
                        <select
                           type="text"
                           name="estado"
                           id="estado"
                           className="bg-gray-50 border border-gray-300  text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Selecione seu estado"
                           required
                           value={state}
                           onChange={(e) => setState(e.target.value)}
                        >
                           <option value="">Selecione o estado</option>
                           {
                              states.map((item, index) =>
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              )
                           }
                        </select>
                     </div>

                     <div>
                        <select
                           type="text"
                           name="cidade"
                           id="cidade"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Selecione sua cidade"
                           required
                           value={city}
                           onChange={e => setCity(e.target.value)}
                        >
                           <option value="">Selecione a cidade</option>
                           {
                              citys.map((item, index) =>
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              )
                           }
                        </select>
                     </div>

                     <div>
                        <input
                           type="text"
                           name="bairro"
                           id="bairro"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu bairro"
                           required
                           value={district}
                           onChange={e => setDistrict(e.target.value)}
                        />
                     </div>

                     <div>
                        <input
                           type="text"
                           name="bairro"
                           id="bairro"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Complemento"
                           value={complement}
                           onChange={e => setComplement(e.target.value)}
                        />
                     </div>

                     <div>
                        <input
                           type="text"
                           name="numero"
                           id="numero"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Número da casa/apto"
                           required
                           value={number}
                           onChange={e => setNumber(e.target.value)}
                        />
                     </div>

                     <div>
                        <input
                           type="text"
                           name="telefone"
                           id="telefone"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                           placeholder="Digite seu telefone"
                           required
                           maxLength={14}
                           value={formatarTelefone(telefone)}
                           onChange={(e) => setTelefone(e.target.value)}
                        />
                     </div>
                  </div>
               </div>
              
               <div className='flex justify-end py-5 mx-4'>
                  <button className="cursor-pointer mx-auto bg-primary p-3 rounded-xl text-white font-semibold text-2xl" type="submit" disabled={loading}>
                     {
                        loading ? <div className='flex items-center justify-center'>
                           <Spinner />
                           Salvando
                        </div> : 'Salvar e continuar'
                     }
                  </button>
               </div>
            </form>
         </div>
      </>

   )
}

export default Adress